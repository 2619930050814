import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  MdxWrapper,
  CategoryTemplate,
  Typography,
} from '../../components'
import Img from '../../components/gatsbyWrappers/Img'
import styles from './PressReleaseTemplate.module.css'
import { format } from 'date-fns'

const propTypes = {
  data: PropTypes.shape({
    pressRelease: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: headerProps,
        featuredImage: PropTypes.string,
        title: PropTypes.string,
        publishedDate: PropTypes.string,
        editor: PropTypes.string,
      }),
    }),
    projects: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  }),
}

export const PressReleaseTemplate = ({ data }) => {
  const {
    pressRelease: {
      frontmatter: { title, publishedDate, editor, featuredImage },
    },
  } = data

  const breadcrumbs = [
    {
      label: 'About Us',
      url: '/about-us',
    },
    {
      label: 'Press Office',
      url: '/about-us/press-office',
    },
    {
      label: title,
    },
  ]

  return (
    <MDXProvider>
      <CategoryTemplate breadcrumbs={breadcrumbs}>
        <Divider thickness={4} />
        <Typography tag="strong" type="boxHeading" className="mb-8">
          Press Office
        </Typography>

        <div className={styles.header}>
          <Typography tag="h1" type="h2" className={styles.title}>
            {title}
          </Typography>
          <Typography tag="span" type="h3" className={styles.date}>
            {format(new Date(publishedDate), 'dd MMMM yyyy')}
          </Typography>
        </div>

        <MdxWrapper>
          {featuredImage && (
            <Img src={featuredImage} alt={title} className={styles.img} />
          )}
          {editor && <MDXRenderer>{editor}</MDXRenderer>}
        </MdxWrapper>
      </CategoryTemplate>
    </MDXProvider>
  )
}

PressReleaseTemplate.propTypes = propTypes

const PressRelease = ({ data }) => {
  const {
    pressRelease: {
      frontmatter: { header },
    },
  } = data
  return (
    <LayoutFullWidth header={header}>
      <PressReleaseTemplate data={data} />
    </LayoutFullWidth>
  )
}

PressRelease.propTypes = propTypes

export default PressRelease

/** @todo only fetch projects by category */
export const pageQuery = graphql`
  query PressReleaseTemplate($slug: String!) {
    pressRelease: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        ...introFields
        title
        publishedDate
        editor
        featuredImage {
          childCloudinaryMediaImage {
            fixed(width: 400) {
              height
              src
              srcSet
              width
            }
          }
        }
      }
    }
  }
`
